import { getMapFromList } from '@/utils';

export const getDefaultObjectImageUrl = () => require('@/assets/Icons/car.svg');

export const getObjectImageUrlOrDefault = mediaId =>
  require('./index').mediaClient.getImageUrl(mediaId?.trim()) ||
  getDefaultObjectImageUrl();

export const camalize = str => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const typeCast = (type, value) => {
  switch (type) {
    case 'bool':
      return !value ? false : !!JSON.parse(value);
    case 'int':
    case 'double':
    case 'number':
      return value === null ? null : Number(value);
    case 'json object':
    case 'json array':
      try {
        const res = JSON.parse(value);
        return res;
      } catch {
        return value;
      }
    default:
      return value;
  }
};

export const flattenObject = sourceObject => {
  const { objectProperties, ...rest } = sourceObject;
  let props = {};
  if (Array.isArray(objectProperties)) {
    props = objectProperties.reduce((acc, property) => {
      acc[property.key] = typeCast(property.type, property.value);
      return acc;
    }, {});
  }
  return {
    ...rest,
    ...props
  };
};

export const prepareObject = sourceObject => {
  const { objectProperties, ...rest } = sourceObject;
  let props = {};
  if (Array.isArray(objectProperties)) {
    props = getMapFromList(
      objectProperties.map(i => ({
        ...i,
        valueRange: sourceObject?.schema?.schemaProperties.find(
          item => item.key === i.key
        )?.valueRange,
        valueSet: sourceObject?.schema?.schemaProperties.find(
          item => item.key === i.key
        )?.valueSet,
        humanReadable:
          sourceObject?.schema?.schemaProperties.find(
            item => item.key === i.key
          )?.description || i.property,
        value: typeCast(i.type, i.value)
      })),
      'key'
    );
  }
  return {
    ...rest,
    ...props
  };
};

export const prepareSchema = sourceObject => {
  const { schemaProperties, ...rest } = sourceObject;
  let props = {};
  if (Array.isArray(schemaProperties)) {
    props = getMapFromList(
      schemaProperties.map(i => ({
        ...i,
        id: i.id,
        key: camalize(`${i.groupName} ${i.property}`),
        value: typeCast(i.type.name, i.defaultValue)
      })),
      'key'
    );
  }
  return {
    ...rest,
    ...props
  };
};

export const getRpcArguments = (controls, rpc) =>
  controls
    .filter(control => control.rpc === rpc && control.rpc !== control.argument)
    .map(i => i.argument);

export const getRpcControl = (controls, rpc) =>
  controls.find(control => control.rpc === rpc && !!control.isRpc);

export const SUB_EVENT_TYPES = {
  insert: 'insert',
  update: 'update',
  delete: 'delete'
};

export const processSubscriptionNode = () => ({
  merge(_, incoming, { cache }) {
    if (incoming.event) {
      const [event, type, id] = incoming.event.split(' ');
      if (event === SUB_EVENT_TYPES.delete) {
        cache.evict({
          id: `${type}:${id}`,
          broadcast: true
        });
      }
    }
  }
});

export const getObjectPropertyByKey = (object, key) => {
  return object?.objectProperties?.find(property => property.key === key);
};

export const getSchemaPropertyByKey = (schema, key) => {
  return schema?.schemaProperties?.find(property => property.key === key);
};

export const patchObjectsByObjectId = (
  id,
  path,
  objectForPath,
  previousObjects
) => {
  const objectIndex = previousObjects.findIndex(object => object.id === id);
  if (objectIndex > -1) {
    const newObject = { ...previousObjects[objectIndex] };
    newObject[path] = [...newObject[path], objectForPath];
    const copyArray = previousObjects.slice(0);
    copyArray.splice(objectIndex, 1, newObject);
    return copyArray;
  }
};

export const savedLat = lat => {
  const inLat = Number(lat);
  return inLat < -90 || inLat > 90 ? 0 : inLat;
};

export const savedLon = lon => {
  const inLon = Number(lon);
  return inLon < -360 || inLon > 360 ? 0 : inLon;
};
